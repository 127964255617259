<template>
    <div class="row">
        <div class="col-12 mt-1">
            <div class="d-flex justify-content-between mt-0 mb-1" style="width:100%">
                <span class="d-flex"> 
                    <!-- MatrixSize -->
                    <div class="dropdown me-2">
                        <button 
                            class="btn btn-sm btn-outline-primary dropdown-toggle" 
                            type="button" 
                            id="matrixSizeDD" 
                            data-bs-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false" 
                            style="cursor: pointer;"
                            :disabled="props.TooManyIDs"
                        >
                            <i class="bi bi-arrows-fullscreen me-1" aria-hidden="true"></i> {{ $t('Matrix Size') }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matrixSizeDD">
                            <button 
                                v-for="(btn) in sizeButtons"
                                :key="btn.size"
                                class="dropdown-item matrix-size" 
                                :class="{ 'active': btn.isActive }"
                                :msize="btn.size" 
                                @click="(e: Event) => { emitAndSetDropdownElementActive(e, btn); }"
                            >{{ btn.name }}</button>
                        </div>
                    </div>

                    <!-- Options -->
                    <div class="dropdown">
                        <button 
                            class="btn btn-sm btn-outline-primary dropdown-toggle" 
                            type="button" 
                            data-bs-auto-close="outside" 
                            data-bs-toggle="dropdown" 
                            id="matrixOptionsDD" 
                            aria-haspopup="true" 
                            aria-expanded="false" 
                            style="cursor: pointer;"
                            :disabled="props.TooManyIDs"
                        >
                            <i class="bi bi-gear-fill" aria-hidden="true"></i> {{ $t('Options') }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="matrixSizeDD" style="width:275px;">
                            <!-- Frequency Options -->
                            <h1 class="dropdown-header">{{ $t('Frequency Label') }}</h1>
                            <div 
                                class="form-check ms-2" 
                                v-for="(btn) in frequencyOptionButtons" 
                                :key="btn.id"
                            >
                                <input 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    :value="btn.value" 
                                    v-model="btn.variable" 
                                    @change="(e: Event) => { setCheckboxEmits(e) }" 
                                    :id="btn.id"
                                >
                                <label class="form-check-label" :title="btn.name" :for="btn.id">
                                    {{ btn.name }}
                                </label>
                            </div>

                            <div class="dropdown-divider" />
                            
                            <!-- Consequence option(s) -->
                            <h1 class="dropdown-header">{{ $t('Consequence Label') }}</h1>
                            <div class="form-check ms-2">
                                <input 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    value="ShowConsequenceText" 
                                    v-model="showConsequenceText" 
                                    @change="(e: Event) => { setCheckboxEmits(e) }" 
                                    id="showConsequenceText"
                                >
                                <label class="form-check-label" :title="$t('Show consequence text')" for="showConsequenceText">
                                    {{ $t('Show consequence text') }}
                                </label>
                            </div>
                        </div>
                    </div>
                </span>
                <span class="d-flex justify-content-center align-items-center">
                    <i class="bi bi-info-square" style="color: #4682b4;"></i> 
                    <a 
                        ref="iconTooltipBtn" class="btn btn-link ps-1"
                        data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true"
                    >
                        {{ $t('Icon explanation') }}
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive } from 'vue';
    import { $t } from 'o365-utils';
    import { alert } from 'o365-vue-services';
    import type { MatrixSize, MatrixSizeButton, FrequencyOptionButton } from 'rams.libraries.vue.components.matrix.MatrixInterfaces.ts';
    import { useTooltip } from 'rams.libraries.vue.components.utilityComps.useTooltip.ts';

    const iconTooltipBtn = ref<HTMLElement | null>(null);
    const tooltipHtmlContent = ref(`
        <span class="d-flex flex-column">
            <i class="bi bi-arrow-left-circle"></i>    <span class="mb-2">${$t('Current assessment timing is before measures') }</span>
            <i class="bi bi-arrow-right-circle"></i>   <span class="mb-2">${$t('Current assessment timing is after measures') }</span>
            <i class="bi bi-arrow-up"></i>             <span class="mb-2">${$t('Current criticality is higher than previous criticality') }</span>
            <i class="bi bi-dash"></i>                 <span class="mb-2">${$t(' Current criticality is the same as the previous criticality') }</span>
            <i class="bi bi-arrow-down"></i>           <span class="mb-2">${$t('Current criticality is lower than previous criticality') }</span>
        </span>
    `)
    useTooltip(iconTooltipBtn, tooltipHtmlContent, true);

    const showFrequencyValue = ref<boolean>(true);
    const showFrequencyText = ref<boolean>(false);
    const showFrequencyPercent = ref<boolean>(false);
    const showConsequenceText = ref<boolean>(false);

    const frequencyOptionButtons = reactive<FrequencyOptionButton[]>([
        {
            value: "ShowFrequencyValue",
            variable: showFrequencyValue,
            id: "showFrequencyValueBtn",
            name: $t('Show frequency value')
        },
        {
            value: "ShowFrequencyText",
            variable: showFrequencyText,
            id: "showFrequencyTextBtn",
            name: $t('Show frequency text')
        },
        {
            value: "ShowFrequencyPercent",
            variable: showFrequencyPercent,
            id: "showFrequencyPercentBtn",
            name: $t('Show frequency percent range')
        }
    ])

    const sizeButtons = reactive<MatrixSizeButton[]>([
        {
            size: 'small', 
            name: $t("Small"),
            isActive: false
        },
        {
            size: 'medium', 
            name: $t("Medium"),
            isActive: true
        },
        {
            size: 'large', 
            name: $t("Large"),
            isActive: false
        }
    ])

    const emits = defineEmits<{
        (event: 'MatrixSize', payload: MatrixSize): void;
        (event: 'ShowFrequencyValue', payload: boolean): void;
        (event: 'ShowFrequencyText', payload: boolean): void;
        (event: 'ShowFrequencyPercent', payload: boolean): void;
        (event: 'ShowConsequenceText', payload: boolean): void;
    }>();

    const props = defineProps({
        "TooManyIDs": { type: Boolean, default: false }
    });

    const emitAndSetDropdownElementActive = (e: Event, selectedSizeBtnObj: MatrixSizeButton) => {
        const target = e.target as HTMLButtonElement;
        const size = target.getAttribute('msize') as string;
        let mClass: string = '';

        switch(size) {
            case 'small':
                mClass = 'table-matrix-sm';
                break;
            case 'medium':
                mClass = 'table-matrix-md';
                break;
            case 'large':
                mClass = 'table-matrix-lg';
                break;
            default:
                mClass = 'table-matrix-md';
                break;
        }

        const matrixSizePayload: MatrixSize = { size: size ? size : 'medium', class: mClass };
        emits('MatrixSize', matrixSizePayload);

        const currActiveElement: MatrixSizeButton = sizeButtons.find(item => item.isActive === true);
        currActiveElement.isActive = false;
        
        selectedSizeBtnObj.isActive = true;
    }

    const setCheckboxEmits = (element: Event) => {
        const el = element.target as HTMLInputElement;
        const emitName = el.getAttribute('value') as string;
        const isChecked = el.checked as boolean;

        switch (emitName) {
            case 'ShowFrequencyValue':
                emits("ShowFrequencyValue", isChecked);
                break;
            case 'ShowFrequencyText':
                emits("ShowFrequencyText", isChecked);
                break;
            case 'ShowFrequencyPercent':
                emits("ShowFrequencyPercent", isChecked);
                break;
            case 'ShowConsequenceText':
                emits("ShowConsequenceText", isChecked);
                break;
            default:
                alert($t('Something went wrong with setting option. Please try again'), 'danger', { autohide: true, delay: 3000 });
        }
    }
</script>